/* Sidebar.css - Updated for mobile responsiveness */

.sidebar {
  height: 100vh;
  width: 80px; /* Fixed width for larger screens */
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  padding: 10px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #9FB4CD;
  font-size: 20px;
  /*box-shadow:rgba(0, 0, 0, 0.07) 0px 6px 32px 0px, rgba(0, 0, 0, 0.04) 0px 4px 16px 0px;*/
}

.sidebar.open {
  z-index: 4;
}

.sidebar svg {
  fill: rgba(0,0,0,0.3);
}

.sidebar-logo {
  height: 32px;
  width: 32px;
  margin: 32px auto 80px auto;
  background-image: url('../assets/h-logo.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.sidebar header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar-link {
  display: block;
  padding: 10px;
  color: black;
  text-decoration: none;
  height: 52px;
  width: 52px;
  border-radius: 100%;
  margin-bottom: 24px;
}

.sidebar-link.active {
  background-color: #111;
}

.sidebar-link.active svg {
  fill: #f9f9f9;
}

.sidebar-link:hover:not(.active) {
  background-color: #30325e10;
}

.logout-btn {
  background: none;
  box-shadow: none;
  border: none;
  font-size: 24px;
}

.sidebar footer {
  margin-bottom: 2rem;
}

.menu-toggle {
  display: none;
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    width: 100%; /* Full width on small screens */
    transform: translateX(-100%); /* Start off-screen */
  }

  .sidebar.open {
    transform: translateX(0); /* Slide in */
  }

  .menu-toggle {
    display: block;
    position: fixed;
    top: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 1000;
  }
}
