.report-comments-page {
    max-width: 90%;
}

.report-comments-page .card-body {
    font-size: 12px;
    text-align: left;
}

.report-comments-page button {
    font-size: 12px;
}