.dataPageContainer {
    margin-top: 16px;
    max-width: 80vw;
    font-size: 14px;
    text-align: left;
    min-height: 100vh;
    min-width: 80vw;
    padding: 40px 0;
    background: #fff;
    border-radius: 20px;
}

.dataPageContainer h5,
.dataPage p {
    font-size: 12px;
}

.dataPageContainer .badge {
    background-color: #eaeaea !important;
    color: #222 !important;
    font-weight: 400;
    font-size: 12px;
    padding: 8px;
}

/*
.dataPageContainer .card {
   background: none;
}*/


.dataPageContainer .card-body {
    padding-top: 0 !important;
}

.dataPageContainer .card-body footer {
    display: flex;
    justify-content: space-between;
}

.dataPageContainer .card-text {
    line-height: 1.7;
}

.dataPageContainer .card-header {
    display: flex;
    justify-content: space-between;
    background: none;
    align-items: center;
    margin-bottom: 16px;
    padding: 0;
}

.dataPageContainer .card-title {
    padding: 8px 12px;
    background: #c9ebfa;
    border-radius: 10px;
    font-family: monospace;
    font-weight: 600;
    color: #2d5668;
    font-size: 12px;
}

.dataPageContainer .card-header button {
    font-size: 12px;
    background: none;
    border: 0;
    color: #222;
    margin-bottom: var(--bs-card-title-spacer-y);
}

.dataPageContainer input,
.dataPageContainer textarea {
    font-size: 12px;
}

@media (max-width: 768px) {
    .dataPageContainer {
        max-width: 100%;
        margin: 0 4px;
        padding: 16px;
    }

    .card-body {
        padding-right: 0;
        padding-left: 0;
    }
}