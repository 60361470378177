.table {
    font-size: 12px;
    max-width: 95%;
    margin: 0 auto;
    text-align: left;
}

.table button {
    font-size: 12px;
}

.supportPageContainer {
    display: flex;
    margin-top: 16px;
    max-width: 100vw;
    font-size: 14px;
    text-align: left;
    height: 100%;
    padding: 40px;
    background: #fff;
    border-radius: 20px;
}

.supportPageContainer .card {
    height: 100%;
    border-radius: 20px;
    margin-bottom: 32px;
}

.supportPageContainer .card-header {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    background: none;
    padding-bottom: 24px;
}

.supportPageContainer .card-header .form-actions {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
}

.supportPageContainer .card-header .form-actions select {
    width: 110px;
    height: 40px;
}

.supportPageContainer .card-body {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    background: none;
    padding-bottom: 24px;
    margin-top: 24px;
}

.supportPageContainer .card-body .chatlog-container {
    border-radius: 13px;
    background-color: #EBF0F5;
    padding: 16px;
    width: 400px;
    margin-left: 16px;
}

.supportPageContainer .card-body .email-container {
    border-radius: 13px;
    background-color: #fff;
    padding: 16px;
    width: 400px;
}

.supportPageContainer .sender {
    background: #111;
    color: #00ff7b;
    padding: 6px 8px;
    border-radius: 8px;
    position: relative;
    font-size: 12px;
}

.supportPageContainer textarea {
    font-size: 14px;
    background-color: #fff;
    border: solid #d1dbe3;
    border-width: 1px 0 0;
    color: #000000de;
    border: none !important;
    border-radius: 10px;
    box-shadow: 1px 2px 4px 0 #00000026;
    background-color: #fff;
    border: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    overflow: visible;
    padding: 12px;
    position: relative;
}

.supportPageContainer .search {
    padding: 0;
}

.supportPageContainer .search input {
    font-size: 12px;
}

.supportPageContainer .list-sort-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.supportPageContainer .list-sort-container button {
    background: none;
    border: none;
    color: #111;
}

.supportPageContainer .message-container button {
    font-size: 12px;
    letter-spacing: 0.2px;
    font-weight: 400;
    background: #fff;
    box-shadow: 1px 3px 2px 0 #00000026;
}