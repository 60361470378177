.homePage {
    height: 100%;
    margin-bottom: 10rem;
    max-width: 1200px;
    width: 96%;
}


.homePage .card__container {
    width: 100%;
    display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}

.homePage .card__container .card{
    margin: 0 8px;
    width: 250px;  
    height: 280px;
    background-color: #fff;
    border-radius: 20px;
    overflow: scroll;
}

@media (min-width: 868px) {
    .homePage .card__container {
    flex-wrap: nowrap;
    }

    .homePage .card__container .card{
        width: 33%;
        min-width: 250px;  
    }
}


@media (max-width: 868px) {
    .homePage {
        width: 100%;
    }
    .homePage .card__container .card{
        width: 30%;
    } 
}
@media (max-width: 568px) {
    .homePage .card__container .card{
        width: 100%;
    } 
}

.homePage .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    padding: 6px;
}

.homePage .card-text a {
    text-align: left;
    font-size: 10px;
    text-wrap: nowrap;
}

.homePage li {
    background: #f9f9f9;
    border-radius: 20px;
    padding: 4px 8px;
    min-width: 200px;
    margin-bottom: 8px;
    font-size: 14px;
}


.homePage h2 {
    margin-bottom: 24px;
}

.homePage .today-links {
    height: 20px;
    display: flex;
    margin-bottom: 16px;
}

.small {
    font-size: 10px;
}

/* Components */

.green {
    background: #D6FBF2!important;
}
.yellow {
    background-color: #FEF4DA!important;
}
.pink {
    background-color: #F9DAFE!important;
}
.beige {
    background-color: #FEDADA!important;
}
.blue {
    background-color: #dae5fe!important;
}
.grey {
    background-color: #f9f9f9!important;
}

.custom-select-input {
    width: 320px;
    font-size: 12px;
    background: #fafafa;
    border: 1px solid #F5F5F5;
    border-radius: 12px;
    padding: 12px;
  }

.homePage .loader {
    margin: 24px auto;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #646584; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1.3s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .homePage .twoColumn-card__container {
    width: 100%;
    display: flex;
}

.homePage .twoColumn-card__container .card {
    width: 100%;
}

.btn_LoadInsights {
    width: 145px;
    border-radius: 8px;
    background-color: rgb(5, 6, 45);
    color: #f9f9f9;
    right: 0;
    margin-top: 12px;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    display: flex;
    align-items: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
    align-self: center;
    transition: all 0.1s ease-in-out;
  }
  
  .btn_LoadInsights::before {
    content: "";
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
    pointer-events: none;
  }

   .btn_LoadInsights:hover {
    background: linear-gradient(45deg, #111, #48ff00);
   }
  
  .btn_LoadInsights:active {
    transform: scale(0.96);
  }
  
  .btn_LoadInsights::after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
    pointer-events: none;
  }
  
  .btn_LoadInsightsLabel {
    font-size: 12px;
    margin-left: auto;
    margin-top: 8px;
    width: 255px;
  }
  
  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }