.datepicker-group {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.datepicker-input {
  position: relative;
}

.react-datepicker__close-icon {
    padding: 6px 6px 0 0!important;
    left: 170px;
}

.custom-select-btn {
  border-radius: 8px;
  border: none;
  font-size: 12px;
  padding: 8px 12px;
  letter-spacing: .2px;
  background-color: #D3D4EE;
  color: #30325E;
  font-weight: bold;
  margin-left: -68px;
  z-index: 3;
  position: absolute;
  right: 5px;
  top: 27%;
}

@media (max-width: 768px) {
  .datepicker-group {
    flex-direction: column;
    margin: 24px 0;
  }
  .custom-select-btn {
    top: 5px;
  }
}

.custom-select-btn:hover {
    background-color: #b8b9dc;
    transition: all .2s ease-out;
}

.custom-select {
    appearance: none;
    background: url('../assets/chevron.svg') no-repeat;
    background-position: right 10px center; /* Adjust the position as needed */
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding-right: 30px; /* Adjust padding to avoid text overlaying the icon */
  }
  
  .custom-select-input {
    width: 320px;
    font-size: 12px;
    background: #fafafa;
    border: 1px solid #F5F5F5;
    border-radius: 12px;
    padding: 12px;
  }
  
  .react-datepicker__close-icon::after {
    background-color: #333!important;
  }
  .react-datepicker__close-icon::after:hover {
    background-color: #5c5959!important;
    transition: all .2s ease-out;
  }
  
  .quick-button-grp {
    display: flex;
  }

  @media (max-width: 768px) {
    .quick-button-grp {
      margin-top: 16px;
    }
  }
  
  
  .quick-button-grp button {
    margin-top: 6px;
    margin-right: 16px;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    padding: 8px;
    letter-spacing: .2px;
    background: #30325E;
    color: #fff;
  }

  .btn-primary {
    background: #30325E;
    color: #fff;
    border: 0;
  }
  
  /* Override margin-right for the last button */
  .quick-button-grp button:last-child {
    margin-right: 0;
  }

.btn-primary:hover, .quick-button-grp button:hover {
    background-color: #494c85;
    transition: all .2s ease-out;
}

.chatLogContainer {
    margin-top: 16px;
    max-width: 90vw;
    font-size: 14px;
    text-align: left;
    min-height: 100vh;
    min-width: 80vw;
    padding: 40px 0;
    background: #fff;
    border-radius: 20px;
}


.chatLogContainer .card {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h3 {
    font-size: 20px;
    font-weight: bold;
}

.h5 {
    font-size: 16px;
}

.card {
    background: rgba(204,204,204,0.2);
    border: 0;
    padding: 16px;
}

.card__header {
    display: flex;
    justify-content: space-between;
}

.card__header button {
    border: 0;
    border-radius: 4px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-size: 10px;
    background-color: #fff;
    padding: 6px 10px;
}

.card__header button:hover {
    background-color: #EDF1F5;
    transition: all .2s ease-out;
}

.bot-msg {
    font-family: monospace;
    font-size: 12px;
    background: #333;
    padding: 4px 6px;
    border-radius: 6px;
    color: yellow;
}

.user-msg {
  font-family: monospace;
  font-size: 12px;
  background: #333;
  padding: 4px 6px;
  border-radius: 6px;
  color: #00ff7b;
}

.loader {
    margin: 30vh auto 0 auto;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #30325E; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .comment-is-fixed {
    font-size: 12px;
    color: #0dcd2a;
  }

  .empty-message {
    color: #c3c4ca;
    margin-top: 40px;
  }