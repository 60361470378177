.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: #000;
  margin-left: 80px;
  padding-top: 2rem;
}

.App-link {
  color: #61dafb;
}

.userHeader {
  display: flex;
  margin-bottom: 32px;
  width: 100%;
  justify-content: flex-start;
  max-width: 80vw;
}

@media (max-width: 768px) {
  .userHeader {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.userHeader__profile {
  border-radius: 100%;
  background-color: #dfe8f5;
  color: #000;
  height: 50px;
  width: 50px;
  margin-right: 16px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userHeader__profile--text {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
}

.userHeader__profile--text span{
font-size: 12px;
color: rgba(0,0,0,.3);
}

.custom-select {
  width: 220px;
  height: 40px;
  margin-left: 24px;
}

.menu-toggle {
  border: 0;
  border-radius: 100%;
  background-color: #a8cbee;
  padding: 8px 12px;
}

.hide {
  display: none;
}

.btn-primary, .btn-danger {
  font-size: 12px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .App-header, .userHeader {
    margin:0 8px;
  }
  .userHeader {
    width: 100%;
  }
  .custom-select {
    margin-top: 16px;
  }
}