code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

.login {
  background-color: antiquewhite;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: url("assets/login-bg.jpg");
  background-position: center;
  background-size: cover;
}

.login-screen {
  width: 343px;
  margin: 24px auto 0 auto;
  background-color: #333;
  padding: 32px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.login-screen img {
  max-width: 80px;
  margin: 0 auto;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand {
  display: flex;
  flex-direction: column;
}

.navbar-brand span {
margin-top: 12px;
font-size: 14px;
}

.nav-item img {
  width: 80px;
}

.visited-pages {
  font-family: monospace;
  font-size: 12px;
  background: #ccc;
  padding: 2px 6px;
  border-radius: 6px;
  color: blue;
}

.raised-text {
  font-size: 14px;
}

.logout-btn {
  margin-left: 16px;
  border-radius: 4px;
  border: none;
  font-size: 10px;
  padding: 4px 10px;
  letter-spacing: .2px;
  background-color: #ccc;
  color: #111;
  font-family: monospace;
  border: 1px solid #111;
}

.mr-8 {
  margin-right: 8px;
}