.settingsPage {
    margin-top: 16px;
    max-width: 80vw;
    font-size: 14px;
    text-align: left;
    min-height: 100vh;
    min-width: 80vw;
    padding: 40px 0;
    background: #fff;
    border-radius: 20px;
}

.settingsPage ul {
    padding: 0;
}
.settingsPage li {
    list-style: none;
    margin: 0;
}

.nav-link {
    color: rgba(0,0,0,0.5);
}

.nav-link:hover {
    color: rgba(0,0,0,0.8);
}